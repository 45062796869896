//
// KaPrintConfig.js
//
import globalConfig from 'app/fuse-configs/globalConfig'
import React from 'react'

const DefaultConfig = {
	settings: {
		layout: {
			config: {
				...globalConfig
			}
		}
	},
	auth: [1],
	routes: [
		{
			path: '/reports/ka-print',
			component: React.lazy(() => import('./KaPrintApp'))
		}
	]
}

const KaPrintConfigs = [DefaultConfig]

export default KaPrintConfigs
